import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import Layout from 'components/Layout';
import { Typography, Box, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Settings(props) {
  const [enabled, setEnabled] = useState(true);

  return (
    <Layout {...props}>
      <Box p={2}>
        <Typography color="textSecondary">*Work in Progress</Typography>
      </Box>
      <Box px={2} py={0} pt={1} display="flex" alignItems="center">
        <Typography color="primary" variant="h5">
          Alerts
        </Typography>
      </Box>
      <Box p={2} display="flex" alignItems="center">
        <Box width={320}>
          <Autocomplete
            options={events}
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Event" variant="outlined" fullWidth />
            )}
          />
        </Box>
        <Box width={320}>
          <Autocomplete
            options={category}
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Category" variant="outlined" fullWidth />
            )}
          />
        </Box>
        <Box width={100}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={enabled}
                onChange={() => setEnabled((e) => !e)}
                value="checkedA"
              />
            }
            label="Enabled"
          />
        </Box>
      </Box>
    </Layout>
  );
}

const events = [
  { title: 'Delivery Vehicles Left', year: 1994 },
  { title: 'Delivery Failed', year: 1972 },
  { title: 'Salesman Returned from Market', year: 1974 },
  { title: 'Amount Collected', year: 1974 },
];

const category = [
  { title: 'All', year: 1994 },
  { title: 'MS001', year: 1972 },
  { title: 'MS002', year: 1974 },
  { title: 'Amount > 5k', year: 1974 },
];
